import React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

// Components
import Layout from '../components/layout';

import routes from '../constants/routes';
import * as styles from '../styles/modules/notfound.module.scss';

const NotFoundPage = () => {
    return (
        <Layout slug={routes.home} title="Not Found">
            <div className={styles.notFound}>
                <h1>Oops!</h1>
                <div className={[styles.contentContainer, "copy-width"].join(' ')}>
                    <StaticImage className={styles.image} layout="fullWidth" placeholder="blurred" alt="" src="../images/404.svg" />
                    <div className={styles.content}>
                        <p>Looks like we had some trouble finding the page you requested. It's probably still under construction...</p>
                        <p>We'll send a crew out ASAP to see what we can do. In the meantime, head on back to the <Link to={routes.home}>main site</Link> to keep on browsing!</p>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default NotFoundPage;
